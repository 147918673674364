import { useLocation } from 'react-router-dom';
import { AUTH_TYPES, SIGN_UP } from '../constants/auth';

const useAuthTypeFromParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return AUTH_TYPES[params.get('loginType')] || SIGN_UP;
};

export default useAuthTypeFromParams;
