import { gql } from '@apollo/client';
import { MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';

const GET_PROVIDERS = gql`
  query GetProviders(
    $companySuppliers: Int!
    $first: Int
    $offset: Int
    $globalFilter: String
  ) {
    getMasterEntities(
      companySuppliers: $companySuppliers
      first: $first
      offset: $offset
      globalFilter: $globalFilter
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...MasterEntityBasicField
        }
      }
    }
  }
  ${MasterEntityBasicField}
`;

export default GET_PROVIDERS;
