import { ApolloProvider } from '@apollo/client';
import client from '@fingo/lib/apollo';
import PrivateRoute from '@fingo/lib/components/router/PrivateRoute';
import { TrackerProvider } from '@fingo/lib/components/tracker';
import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import SnackbarAlert from '@fingo/lib/contexts/snack-bar';
import { useGetUser, useSelectedCompany } from '@fingo/lib/hooks';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import theme from '@fingo/lib/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect } from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import Layout from './components/layout';
import UtmCampaignTracker from './components/utmCampaigns/UtmCampaignTracker';
import Game from './game/index';
import { pilotRutes } from './routes';
import {
  App,
  AutomaticCession,
  ChangeAssignmentCessionType,
  Landing,
  PasswordRecovery,
  PreOfferEvaluation,
  QuickRegistration,
  TermsAndConditions,
} from './views';
import LoginLandingWithWave from './views/LoginLandingWithWave';
import HotjarProvider from './views/App/HotjarProvider';
import OnboardingAuth from './views/App/supplierAutomate/onboarding/SupplierOnboardingAuth';
import EmailVerification from './views/EmailVerification';
import OTPLogin from './views/OTPLogin';
import ExternalLoginLanding from './features/landings/views/ExternalLoginLanding';

// eslint-disable-next-line react/prop-types
const AppContainer = ({ children }) => {
  const history = useHistory();
  const { update } = useIntercom();
  const user = useGetUser();
  const selectedCompany = useSelectedCompany();
  const country = useGetCountryFromUrl();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (user) {
        update({
          name: user.completeName,
          userId: user.id,
          email: user.mailProvided || user.email,
          phone: user.phoneNumber,
          company: {
            companyId: selectedCompany?.id,
            name: selectedCompany?.name,
          },
        });
      } else {
        update();
      }
    }
  }, [history, user]);
  if (!country) return null;
  return children;
};

const ApolloRouting = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <StyledEngineProvider injectFirst>
      <SnackbarAlert />
      <Router>
        <AppContainer>
          <UtmCampaignTracker>
            <TranslationProvider>
              <HotjarProvider>
                <TrackerProvider
                  frontendName="user-frontend"
                  googleAnalyticsTag={
                    process.env.REACT_APP_GOOGLE_ANALYTICS_TAG
                  }
                >
                  <Layout>
                    <Switch>
                      <PrivateRoute path="/app" component={App} />
                      <PrivateRoute path="/teuber" component={Game} />
                      <Route
                        exact
                        path="/register"
                        component={LoginLandingWithWave}
                      />
                      <Route
                        exact
                        path="/quick-registration"
                        component={QuickRegistration}
                      />
                      <Route
                        exact
                        path="/login"
                        component={LoginLandingWithWave}
                      />
                      <Route
                        exact
                        path="/provider-login"
                        component={OnboardingAuth}
                      />
                      <Route
                        exact
                        path="/preoffer-evaluation/:preofferHash"
                        component={PreOfferEvaluation}
                      />
                      <Route
                        exact
                        path="/change-assignment-cession-type/:invoiceHash"
                        component={ChangeAssignmentCessionType}
                      />
                      <Route
                        exact
                        path="/automatic-cession-config/:automaticCessionConfigHash"
                        component={AutomaticCession}
                      />
                      <Route
                        exact
                        path="/external-login"
                        component={ExternalLoginLanding}
                      />
                      <Route exact path="/" component={Landing} />
                      <Route
                        exact
                        path="/terms"
                        component={TermsAndConditions}
                      />
                      <Route
                        exact
                        path="/password-recovery"
                        component={PasswordRecovery}
                      />
                      <Route
                        path="/email-verification/:encodedEmail/:token"
                        component={EmailVerification}
                      />
                      <Route
                        path="/otp-verification/:clientId"
                        component={OTPLogin}
                      />
                      {pilotRutes.map(({ path, component }) => (
                        <Route
                          exact
                          path={path}
                          component={component}
                          key={path}
                        />
                      ))}
                    </Switch>
                  </Layout>
                </TrackerProvider>
              </HotjarProvider>
            </TranslationProvider>
          </UtmCampaignTracker>
        </AppContainer>
      </Router>
    </StyledEngineProvider>
  </GoogleOAuthProvider>
);

const Routing = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ApolloProvider client={client}>
            <IntercomProvider
              appId="b72hnksj"
              autoBoot
              shouldInitialize={process.env.NODE_ENV === 'production'}
            >
              <ApolloRouting />
            </IntercomProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);

render(<Routing />, document.getElementById('root'));
