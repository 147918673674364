import { useCallback } from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { useMutation, useQuery } from '@apollo/client';
import { useFilteredQuery } from '@fingo/lib/hooks';
import { orderingSimulationSelection } from '@fingo/lib/apollo/cache/localQuery';
import { cleanOrderingShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import { ORDERING_PURCHASE_ORDERS, PURCHASE_ORDERS_SEND_EVALUATE } from '@fingo/lib/graphql';
import { formatYearMonthDay } from '@fingo/lib/helpers';

const useSimulateOrdering = ({ ...mutationOptions }) => {
  const {
    data: { selectedOrderingIds, orderingInvoiceIssuedSelected },
  } = useQuery(orderingSimulationSelection);
  const {
    data: purchaseOrderData,
    loading: loadingOrders,
    deleteQueryFromCache,
  } = useFilteredQuery(ORDERING_PURCHASE_ORDERS, {
    variables: {
      first: 100,
      id_In: selectedOrderingIds,
    },
    skip: !selectedOrderingIds.length,
  });
  const calculateOrderingRate = useCallback(
    (node) => {
      const daysFromNow = -dayjs()
        .startOf('day')
        .diff(orderingInvoiceIssuedSelected[node.id], 'days');
      return (
        node.orderingsimulation.orderingMonthlyRate * (daysFromNow / 30 / 100)
      );
    },
    [orderingInvoiceIssuedSelected],
  );
  const calculateOrderingInterest = useCallback(
    (node) => {
      const withoutRetention = node.orderingsimulation.orderingFinancedAmount.amount;
      return withoutRetention * calculateOrderingRate(node);
    },
    [calculateOrderingRate],
  );
  const calculatePaymentAmount = useCallback(
    (node) => {
      const withoutRetention = node.orderingsimulation.orderingFinancedAmount.amount
        - node.orderingsimulation.orderingCommission.amount
        - node.orderingsimulation.otherDiscounts.amount;
      return withoutRetention - calculateOrderingInterest(node);
    },
    [calculateOrderingInterest],
  );
  const result = useMutation(PURCHASE_ORDERS_SEND_EVALUATE, {
    variables: {
      purchaseOrders: purchaseOrderData?.purchaseOrders.edges.map(
        ({ node }) => ({
          purchaseOrderId: node.id,
          orderingInterest: calculateOrderingInterest(node),
          orderingPaymentAmount: calculatePaymentAmount(node),
          invoiceIssuedDate:
            orderingInvoiceIssuedSelected[node.id]
            && formatYearMonthDay(orderingInvoiceIssuedSelected[node.id]),
        }),
      ),
    },
    ...mutationOptions,
    onCompleted: () => {
      deleteQueryFromCache();
      cleanOrderingShoppingCart();
      mutationOptions.onCompleted();
    },
  });
  if (loadingOrders) return [null, result[1]];
  return result;
};

export default useSimulateOrdering;
