import { useTranslation } from 'react-i18next';
import { useSelectedCompany } from '@fingo/lib/hooks';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import {
  getPersonalInformation,
  getSiiCredentials,
  getInvoiceProviderCredentials,
  getDigitalCretificateCredentials,
  getBankAccountCredentials,
  getSupplierPortalCredentials,
  getCreditLineDocumentCredentials,
  getOperationalDocumentsCredentials,
} from '../helpers/credentials';

const useCompanyCredentials = () => {
  const selectedCompany = useSelectedCompany();
  const { t } = useTranslation();
  const country = useGetCountryFromUrl();
  const nationalTaxService = t('National Tax Service acronym');

  return [
    getPersonalInformation(),
    getSiiCredentials(selectedCompany, nationalTaxService),
    getInvoiceProviderCredentials(selectedCompany, nationalTaxService, country),
    getDigitalCretificateCredentials(selectedCompany, nationalTaxService, country),
    getBankAccountCredentials(selectedCompany, nationalTaxService),
    getSupplierPortalCredentials(selectedCompany, country),
    getCreditLineDocumentCredentials(selectedCompany, nationalTaxService, country),
    getOperationalDocumentsCredentials(selectedCompany, nationalTaxService, country),
  ];
};

export default useCompanyCredentials;
