import { useMutation } from '@apollo/client';
import { REGISTER_USER } from '@fingo/lib/graphql';
import Cookies from 'universal-cookie';

const useRegister = () => {
  const cookies = new Cookies();
  const utmCampaign = cookies.get('utm_campaign') || null;

  const [registerUser] = useMutation(REGISTER_USER, {
    variables: {
      utmCampaign,
    },
  });

  return { signUp: registerUser };
};

export default useRegister;
