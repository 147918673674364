export const statusToActiveStep = {
  EVALUATION: 0,
  OFFERED: 0,
  REJECTED: 0,
  REJECTEDPREOFFEREVALUATION: 0,
  PENDINGASSIGNMENTDOCUMENT: 1,
  PENDINGSIGNATURE: 1,
  PENDINGTRANSFER: 2,
  PENDINGINVOICES: 3,
  PROCESSINGINVOICES: 3,
  PENDINGCOLLECTION: 3,
  FACTORING: 3,
  FINISHED: 3,
};
export const statusToLabel = {
  CREATED: 0,
  SIMULATION: 0,
  EVALUATION: 1,
  REJECTED: 2,
  REJECTEDPREOFFEREVALUATION: 2,
  OFFERED: 3,
  PENDINGASSIGNMENTDOCUMENT: 3,
  PENDINGSIGNATURE: 3,
  PENDINGTRANSFER: 4,
  PENDINGCOLLECTION: 5,
  PENDINGINVOICES: 6,
  PROCESSINGINVOICES: 7,
  FACTORING: 8,
  FINISHED: 9,
};
export const statusToSpanish = {
  SIMULATION: 'Simulación',
  CREATED: 'Creada',
  EVALUATION: 'En evaluación',
  REJECTED: 'Rechazada',
  REJECTEDPREOFFEREVALUATION: 'Eval. de preoferta rechazada',
  OFFERED: 'Aceptada',
  PENDINGASSIGNMENTDOCUMENT: 'Pendiente de mandato',
  PENDINGSIGNATURE: 'Pendiente de mandato',
  PENDINGTRANSFER: 'Pendiente de giro',
  PENDINGCOLLECTION: 'En recaudación',
  PENDINGINVOICES: 'Pendiente de factura',
  PROCESSINGINVOICES: 'Procesando Cesión',
  FACTORING: 'En factoring',
  FINISHED: 'Finalizada',
};

export const statusToStepLabels = (status) => {
  let labels = [];
  if (statusToLabel[status] === 0) {
    labels = ['Simulación', 'Firma mandato', 'Depósito', 'Factoring'];
  }
  if (statusToLabel[status] === 1) {
    labels = ['En evaluación', 'Firma mandato', 'Depósito', 'Factoring'];
  }
  if (statusToLabel[status] === 2) {
    labels = ['Rechazado', 'Firma mandato', 'Depósito', 'Factoring'];
  }
  if (statusToLabel[status] === 3) {
    labels = ['Aceptada', 'Firma mandato', 'Depósito', 'Factoring'];
  }
  if (statusToLabel[status] === 4) {
    labels = ['Aceptada', 'Firma mandato', 'Por depositar', 'Factoring'];
  }
  if (statusToLabel[status] === 5) {
    labels = ['Aceptada', 'Firma mandato', 'Depositada', 'En recaudación'];
  }
  if (statusToLabel[status] === 6) {
    labels = ['Aceptada', 'Firma mandato', 'Depositada', 'Espera factura'];
  }
  if (statusToLabel[status] === 7) {
    labels = ['Aceptada', 'Firma mandato', 'Depositada', 'Procesando cesión'];
  }
  if (statusToLabel[status] === 8) {
    labels = ['Aceptada', 'Firma mandato', 'Depositada', 'Factoring'];
  }
  if (statusToLabel[status] === 9) {
    labels = ['Aceptada', 'Firma mandato', 'Depositada', 'Recaudada'];
  }
  return labels;
};

export const orderStatus = {
  Created: 'CREATED',
  Simulation: 'SIMULATION',
  Evaluation: 'EVALUATION',
  Offered: 'OFFERED',
  Rejected: 'REJECTED',
  PendingAssignmentDocument: 'PENDINGASSIGNMENTDOCUMENT',
  PendingSignature: 'PENDINGSIGNATURE',
  PendingTransfer: 'PENDINGTRANSFER',
  PendingInvoices: 'PENDINGINVOICES',
  PendingCollection: 'PENDINGCOLLECTION',
  Factoring: 'FACTORING',
  Finished: 'FINISHED',
};

export const hasButtonDisable = (status) => {
  if (status === orderStatus.Offered
    || status === orderStatus.PendingInvoices
    || status === orderStatus.Simulation) {
    return false;
  }
  return true;
};
