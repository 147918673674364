import { useMemo } from 'react';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useShowingTyC from './useShowingTyC';
import {
  analyticsRoutes,
  homeRoutes,
  notificationsRoutes,
  profileRoutes,
  purchasesRoutes,
  salesRoutes,
  supplierAutomateRoutes,
} from '../routes';

const routeSetter = (user, showTyc, selectedCompany, country, isMobile) => [
  ...homeRoutes(user, showTyc),
  ...salesRoutes(user, showTyc, country),
  ...purchasesRoutes(user, showTyc, country),
  ...analyticsRoutes(user, showTyc, country),
  ...profileRoutes(selectedCompany, isMobile),
  ...notificationsRoutes,
  ...supplierAutomateRoutes,
];
const useFingoRoutes = () => {
  const currentUser = useGetUser();
  const showTyc = useShowingTyC();
  const country = useGetCountryFromUrl();
  const selectedCompany = useMemo(() => currentUser?.selectedCompany);
  const isMobile = useIsMobile();
  const routes = currentUser
    ? routeSetter(currentUser, showTyc, selectedCompany, country, isMobile)
    : [];
  return routes.filter((route) => !!route.show);
};

export default useFingoRoutes;
