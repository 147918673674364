import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useShoppingCartSummary, useOfferShoppingCart } from '@fingo/lib/hooks';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';

const useOfferShoppingCartSummary = () => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const { shoppingCartData, loading } = useOfferShoppingCart();

  const formatedShoppingCart = useShoppingCartSummary(shoppingCartData);

  const noOffers = useMemo(
    () => shoppingCartOffers.length === 0,
    [shoppingCartOffers],
  );

  const hasOffersWithoutDateToPay = useMemo(
    () => shoppingCartOffers.some((off) => off.dateToPay === null),
    [shoppingCartOffers],
  );

  const { orderingDebt, hasPurchaseOrdersNearToDebt } = shoppingCartData;

  const tooltipTitle = useMemo(() => {
    if (noOffers) {
      return 'Seleccione ofertas en el panel para avanzar';
    }
    if (hasOffersWithoutDateToPay) {
      return 'Seleccione todas las fechas de pago de las ofertas elegidas';
    }
    return '';
  }, [noOffers, hasOffersWithoutDateToPay]);

  const actionDisabled = useMemo(
    () => !!tooltipTitle.length || orderingDebt.amount > 0,
    [tooltipTitle, orderingDebt],
  );

  return {
    summaryItems: formatedShoppingCart,
    summaryLoading: loading,
    actionDisabled,
    orderingDebt: orderingDebt.amount,
    hasPurchaseOrdersNearToDebt,
    tooltipTitle,
  };
};

export default useOfferShoppingCartSummary;
