import { gql } from '@apollo/client';

export const ACEPTA_LOGIN_SESSION_ID = gql`
  mutation aceptaAuthSessionId(
    $sessionId: String!
    $buttonFlag: String
    $clientRut: String
    $companyRut: String
  ) {
    aceptaAuthSessionId(
      sessionId: $sessionId
      buttonFlag: $buttonFlag
      clientRut: $clientRut
      companyRut: $companyRut
    ) {
      token
      refreshToken
      actualCompanyRut
    }
  }
`;

export const ACEPTA_LOGIN = gql`
  mutation AceptaAuth($rut: String!, $password: String!) {
    aceptaAuth(rut: $rut, password: $password) {
      token
      refreshToken
    }
  }
`;

export const SENEGOCIA_AUTH = gql`
  mutation SenegociaAuth(
    $token: String!
    $loginFlag: String
    $isOrdering: Boolean!
  ) {
    senegociaAuth(
      token: $token
      loginFlag: $loginFlag
      isOrdering: $isOrdering
    ) {
      token
      refreshToken
    }
  }
`;

export const SEND_EMAIL_VERIFICATION_MAIL = gql`
  mutation sendEmailVerificationMail {
    sendEmailVerificationMail {
      success
    }
  }
`;
