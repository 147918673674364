import { useLazyQuery } from '@apollo/client';
import { CHECK_USERNAME_EMAIL } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import Cookies from 'universal-cookie';

const useEmailValidation = (landingTag) => {
  const { addAlert } = useSnackBars();
  const cookies = new Cookies();
  const utmCampaign = cookies.get('utm_campaign') || null;

  const [checkUsernameEmail] = useLazyQuery(CHECK_USERNAME_EMAIL, {
    variables: {
      landingTag,
      utmCampaign,
    },
    onError: () => {
      addAlert({
        id: 'no-user-associated',
        message: 'Hubo un error.',
        color: 'error',
        severity: 'error',
      });
    },
  });
  return { validateEmail: checkUsernameEmail };
};

export default useEmailValidation;
